import { FC, useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { Box, Typography, IconButton, Button, Modal } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'

import styles from './styles.module.scss'

interface IProps {
  open: boolean
  onClose: () => void
  handleConfirm: (date: Date) => void
}

const RFAExportModal: FC<IProps> = ({ open, onClose, handleConfirm }) => {
  const [date, setDate] = useState(dayjs(new Date()))

  return (
    <Modal className={styles.modal} open={open} onClose={onClose}>
      <Box className={styles.modalContent}>
        <Box>
          <Box className={styles.header}>
            <Typography className={styles.title} id="title">
              Export RFA PDF
            </Typography>
            <IconButton data-testid="closeButton" onClick={onClose}>
              <CloseOutlinedIcon />
            </IconButton>
          </Box>
          <Box className={styles.selects}>
            <Box className={styles.fieldContainer}>
              <Typography>Date:</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box className={styles.dateRange}>
                  <DatePicker
                    value={date}
                    onChange={(value) => setDate(value as Dayjs)}
                  />
                </Box>
              </LocalizationProvider>
            </Box>
          </Box>
          <Box className={styles.actions}>
            <Button
              variant="outlined"
              onClick={() => handleConfirm(date.toDate())}
            >
              Download
            </Button>
            <Button variant="outlined" onClick={onClose}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default RFAExportModal
