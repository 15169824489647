import { useContext, useState } from 'react'
import { Box } from '@mui/material'
import { PlaybookContext } from '../../contexts/playbooks.context'
import Requests from '../../components/features/SpectrumManagerDashboard/Requests'
import ArcDiagram from '../../components/features/SpectrumManagerDashboard/ArcDiagrams'
import { TabPanel } from '../../components/shared/TabPanel'
import { Licenses } from '../../components/features/SpectrumManagerDashboard/Licenses'
import DashboardSidebar from '../../components/features/SpectrumManagerDashboard/Sidebar'
import { FieldsControls } from '../../components/features/SpectrumManagerDashboard/FieldsControls'
import { DevicesPlatforms } from '../../components/features/SpectrumManagerDashboard/DevicesPlatforms'
import { IExerciseRequestDetails } from '../../types/spectrum-manager.type'

import styles from './styles.module.scss'

const SpectrumManagerDashboard = () => {
  const [value, setValue] = useState(0)
  const [selectedRequest, setSelectedRequest] =
    useState<IExerciseRequestDetails | null>(null)

  const { isLightMode } = useContext(PlaybookContext)

  return (
    <Box
      className={styles.spectrumManager}
      style={{ backgroundColor: isLightMode ? 'white' : '#04111a' }}
    >
      <DashboardSidebar value={value} setValue={setValue} />
      <Box
        className={styles.content}
        style={{ backgroundColor: isLightMode ? 'white' : '#13222e' }}
      >
        <TabPanel value={value} index={0}>
          <Requests
            onRequestSelect={(request) => setSelectedRequest(request)}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ArcDiagram />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <FieldsControls />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <DevicesPlatforms />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Licenses />
        </TabPanel>
      </Box>
    </Box>
  )
}

export default SpectrumManagerDashboard
