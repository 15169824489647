import {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Box } from '@mui/material'
import TableComponent from '../../../shared/Table'
import { FrequencyPlansTableConfigs } from '../../../../utils/constants'
import { PlaybookContext } from '../../../../contexts/playbooks.context'
import { SpectrumManagerContext } from '../../../../contexts/spectrum.context'
import { getAllOpenFrequency } from '../../../../services/spectrum-manager.service'
import { IOpenFrequencyRequest } from '../../../../types/spectrum-manager.type'

import styles from './styles.module.scss'

interface IProps {
  setSelectedOpenFreq: Dispatch<
    SetStateAction<{
      exerciseId: number
      end_freq_long: number
      start_freq_long: number
      assigned_freq_id: number
    }>
  >
}

export const OpenFrequencyRequests: FC<IProps> = ({ setSelectedOpenFreq }) => {
  const [freqStatus, setFreqStatus] = useState('NOT_STARTED')
  const [openFrequencyRequests, setOpenFrequencyRequests] = useState<
    IOpenFrequencyRequest[]
  >([])

  const { isLightMode } = useContext(PlaybookContext)
  const { filterOptions } = useContext(SpectrumManagerContext)
  const { getAccessTokenSilently } = useAuth0()

  const getOpenFrequencyRequests = async () => {
    const data = await getAllOpenFrequency(await getAccessTokenSilently(), {
      ...filterOptions,
      freqStatus,
    })

    if (data.data) {
      setOpenFrequencyRequests(data.data)
    }
  }

  useEffect(() => {
    getOpenFrequencyRequests()
  }, [filterOptions, freqStatus])

  return (
    <Box className={styles.openFrequencies}>
      <Box className={styles.tableContainer}>
        <TableComponent
          height={500}
          isLightMode={isLightMode}
          data={openFrequencyRequests}
          freqStatusSelect
          configs={FrequencyPlansTableConfigs}
          handleRefetch={(_, status) => setFreqStatus(status || 'NOT_STARTED')}
          handleSelect={(item: IOpenFrequencyRequest) =>
            setSelectedOpenFreq({
              start_freq_long: item.start_freq_long,
              end_freq_long: item.end_freq_long,
              exerciseId: item.exercise_id,
              assigned_freq_id: item.assigned_freq_id,
            })
          }
        />
      </Box>
    </Box>
  )
}
