import Box from '@mui/material/Box'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import MuiDrawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import CssBaseline from '@mui/material/CssBaseline'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ListItemButton from '@mui/material/ListItemButton'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { styled, Theme } from '@mui/material/styles'
import { ManagerDashboardTabs } from '../../../../utils/constants'
import { Dispatch, SetStateAction, useState } from 'react'

const drawerWidth = 240

const openedMixin = (theme: Theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme: Theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}))

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  borderRight: '1px solid white',
  variants: [
    {
      props: ({ open }) => open,
      style: {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      },
    },
    {
      props: ({ open }) => !open,
      style: {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      },
    },
  ],
}))

interface IProps {
  value: number
  setValue: Dispatch<SetStateAction<number>>
}

const Sidebar: React.FC<IProps> = ({ value, setValue }) => {
  const [open, setOpen] = useState(true)

  const handleDrawerClose = () => {
    setOpen(!open)
  }

  const handleTabSelect = (index: number) => {
    setValue(index)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        position: 'relative',
        borderRight: '1px solid #e9e5e5',
      }}
    >
      <CssBaseline />
      <Drawer
        sx={{
          '& .MuiDrawer-root': {
            position: 'absolute',
          },
          '& .MuiPaper-root': {
            position: 'absolute',
          },
        }}
        variant="permanent"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider sx={{ borderColor: '#e9e5e5' }} />
        <List>
          {ManagerDashboardTabs.map((tab, index) => (
            <ListItem key={tab.label} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                onClick={() => handleTabSelect(index)}
                sx={[
                  {
                    minHeight: 48,
                    px: 2.5,
                    background:
                      value === index ? 'rgb(200 196 196 / 20%)' : 'unset',
                    '&:hover': {
                      background: 'rgb(200 196 196 / 20%)',
                    },
                  },
                  open
                    ? {
                        justifyContent: 'initial',
                        background:
                          value === index ? 'rgb(200 196 196 / 20%)' : 'unset',
                      }
                    : {
                        justifyContent: 'center',
                        background:
                          value === index ? 'rgb(200 196 196 / 20%)' : 'unset',
                      },
                ]}
              >
                <ListItemIcon
                  sx={[
                    {
                      minWidth: 0,
                      justifyContent: 'center',
                    },
                    open
                      ? {
                          mr: 3,
                        }
                      : {
                          mr: 'auto',
                        },
                  ]}
                >
                  <tab.icon />
                </ListItemIcon>
                <ListItemText
                  primary={tab.label}
                  sx={[
                    open
                      ? {
                          opacity: 1,
                        }
                      : {
                          opacity: 0,
                        },
                  ]}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </Box>
  )
}

export default Sidebar
