import { FC, useContext, useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Box } from '@mui/material'
import TableComponent from '../../../shared/Table'
import {
  IFrequencyPool,
  IFrequencyPool_113_114_155,
  IFrequencyPoolTableEquipment,
} from '../../../../types/spectrum-manager.type'
import { PlaybookContext } from '../../../../contexts/playbooks.context'
import {
  getAllLicenses,
  getAllFrequencyPool_113_114_115,
  getAllFrequencyPoolTableEquipments,
  nominateFrequency,
  updateSfaf,
} from '../../../../services/spectrum-manager.service'
import SFAFView from '../../Modals/SFAFViewModal'
import NominateModal from '../../Modals/NominateModal'
import ImportSfafModal from '../../Modals/ImportSfafModal'
import { FrequencyPoolAssignmentsTableConfig } from '../../../../utils/constants'

import styles from './styles.module.scss'

interface IProps {
  selectedOpenFreq: {
    exerciseId: number
    end_freq_long: number
    start_freq_long: number
    assigned_freq_id: number
  }
}

export const FrequencyPoolAssignments: FC<IProps> = ({ selectedOpenFreq }) => {
  const [sfaf, setSfaf] = useState('')
  const [importSfaf, setImportSfaf] = useState(false)
  const [sfafFreqId, setSfafFreqId] = useState(0)
  const [isNominating, setIsNominating] = useState(false)
  const [nominationOptions, setNominationOptions] = useState<{
    id: number
    field_110_from: string
    field_102: string
  } | null>(null)
  const [frequencyPlans, setFrequencyPlans] = useState<IFrequencyPool[]>([])
  const [frequencyPools_113_114_115, setFrequencyPools_113_114_115] = useState<
    IFrequencyPool_113_114_155[]
  >([])
  const [frequencyPoolTableEquipments, setFrequencyPoolTableEquipments] =
    useState<IFrequencyPoolTableEquipment[]>([])

  const { isLightMode } = useContext(PlaybookContext)

  const { getAccessTokenSilently } = useAuth0()

  const getOpenFrequencyRequests = async () => {
    const data = await getAllLicenses(await getAccessTokenSilently(), {
      start_freq_long: selectedOpenFreq.start_freq_long,
      end_freq_long: selectedOpenFreq.end_freq_long,
      exerciseId: selectedOpenFreq.exerciseId,
    })

    if (data.data) {
      setFrequencyPlans(
        data.data.map((item) => ({
          ...item,
          field_110_from:
            parseFloat(item.field_110_from) > 1000000
              ? 'M' + parseFloat(item.field_110_from) / 1000000
              : 'K' + parseFloat(item.field_110_from) / 1000,
        })),
      )
    }
  }

  const handleNominate = (id: number) => {
    setIsNominating(true)
    getFrequencyPoolTableEquipments(id)
    getFrequencyPool_113_114_115(id)
  }

  const handleSfafChange = async (sfaf: string) => {
    setSfaf(sfaf)

    await updateSfaf(await getAccessTokenSilently(), sfafFreqId, {
      sfaf,
    })
  }

  const getFrequencyPool_113_114_115 = async (id: number) => {
    const data = await getAllFrequencyPool_113_114_115(
      await getAccessTokenSilently(),
      id,
    )

    if (data.data) {
      setFrequencyPools_113_114_115(data.data)
    }
  }

  const getFrequencyPoolTableEquipments = async (id: number) => {
    const data = await getAllFrequencyPoolTableEquipments(
      await getAccessTokenSilently(),
      id,
    )

    if (data.data) {
      setFrequencyPoolTableEquipments(data.data)
    }
  }

  const handleAssign = async (f114: string) => {
    if (nominationOptions) {
      await nominateFrequency(
        await getAccessTokenSilently(),
        selectedOpenFreq.assigned_freq_id,
        {
          frequency_pool_id: nominationOptions.id,
          value_144: f114,
          field_102: nominationOptions.field_102,
          field_110_from: nominationOptions.field_110_from,
        },
      )
    }
  }

  useEffect(() => {
    getOpenFrequencyRequests()
  }, [selectedOpenFreq])

  return (
    <Box className={styles.frequencyPlans}>
      <Box className={styles.tableContainer}>
        <TableComponent
          height={500}
          isLightMode={isLightMode}
          data={frequencyPlans}
          importSfaf
          configs={FrequencyPoolAssignmentsTableConfig}
          handleRefetch={() => {}}
          handleOpenSfaf={() => setImportSfaf(true)}
          handleView={(item: IFrequencyPool) => {
            setSfaf(item.raw_sfaf)
            setSfafFreqId(item.id)
          }}
          handleNominate={(item: IFrequencyPool) => {
            handleNominate(item.id)
            setNominationOptions({
              id: item.id,
              field_102: item.field_102,
              field_110_from: item.field_110_from,
            })
          }}
        />
      </Box>
      <SFAFView
        isOpen={!!sfaf}
        sfaf={sfaf}
        handleChange={handleSfafChange}
        isLightMode={isLightMode}
        onClose={() => setSfaf('')}
      />
      <NominateModal
        isOpen={isNominating}
        isLightMode={isLightMode}
        handleAssign={handleAssign}
        onClose={() => setIsNominating(false)}
        frequencyPoolTableEquipments={frequencyPoolTableEquipments}
        frequencyPools_113_114_115={frequencyPools_113_114_115}
      />
      <ImportSfafModal
        isOpen={importSfaf}
        isLightMode={isLightMode}
        onClose={() => setImportSfaf(false)}
      />
    </Box>
  )
}
