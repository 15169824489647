import { FC, useEffect, useState } from 'react'
import { Box, Select, MenuItem, Typography, IconButton } from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { IPagination } from '../../../types/playbook.type'

import styles from './styles.module.scss'

interface IProps {
  pagination: IPagination
  isLightMode: boolean
  onPageChange: (skip: number) => void
  handleRefresh: () => void
  handleDownload?: () => void
  onRowsPerPageChange: (take: number) => void
}

const TableToolbar: FC<IProps> = ({
  pagination,
  isLightMode,
  onPageChange,
  handleRefresh,
  handleDownload,
  onRowsPerPageChange,
}) => {
  const [page, setPage] = useState(0)
  const [paginationText, setPaginationText] = useState('')

  const handleChangePage = (newSkip: number) => {
    onPageChange(newSkip)
  }

  const handleChangeRowsPerPage = (value: number) => {
    setPage(0)
    onPageChange(0)
    onRowsPerPageChange(value)
  }

  useEffect(() => {
    const startItem = pagination.skip * pagination.take + 1
    const endItem = Math.min(
      pagination.count,
      (pagination.skip + 1) * pagination.take,
    )
    setPaginationText(`${startItem}-${endItem} of ${pagination.count}`)
  }, [pagination])

  return (
    <Box className={styles.toolbar}>
      <Box className={styles.content}>
        <Box className={styles.middleBar}>
          {/* <IconButton>
            <FilterAltIcon sx={{ color: 'gray' }} />
          </IconButton>
          <IconButton onClick={handleDownload}>
            <FileDownloadIcon sx={{ color: 'gray' }} />
          </IconButton>
          <IconButton onClick={handleRefresh}>
            <RefreshIcon sx={{ color: 'gray' }} />
          </IconButton> */}
        </Box>
        <Box className={styles.endBar} display="flex" alignItems="center">
          <Select
            value={pagination.take}
            onChange={(evt) =>
              handleChangeRowsPerPage(Number(evt.target.value))
            }
            variant="standard"
            disableUnderline
            sx={{ color: isLightMode ? 'black' : 'white' }}
          >
            {[10, 25, 50].map((takeOption) => (
              <MenuItem key={takeOption} value={takeOption}>
                {takeOption}
              </MenuItem>
            ))}
          </Select>
          <Typography sx={{ marginLeft: 2 }}>{paginationText}</Typography>
          <Box>
            <IconButton
              disabled={pagination.skip === 0}
              onClick={() => {
                setPage((prev) => prev - 1)
                handleChangePage(page - 1)
              }}
              sx={{ color: isLightMode ? 'black' : 'white' }}
            >
              <ArrowBackIosIcon />
            </IconButton>

            <IconButton
              disabled={pagination.skip * pagination.take >= pagination.count}
              onClick={() => {
                setPage((prev) => prev + 1)
                handleChangePage(page + 1)
              }}
              sx={{ color: isLightMode ? 'black' : 'white' }}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default TableToolbar
