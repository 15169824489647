import { FC, useState } from 'react'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import {
  Box,
  Typography,
  IconButton,
  Button,
  Modal,
  Select,
  MenuItem,
} from '@mui/material'
import {
  IFrequencyPool_113_114_155,
  IFrequencyPoolTableEquipment,
} from '../../../../types/spectrum-manager.type'

import styles from './styles.module.scss'

interface IProps {
  isOpen: boolean
  frequencyPools_113_114_115: IFrequencyPool_113_114_155[]
  frequencyPoolTableEquipments: IFrequencyPoolTableEquipment[]
  isLightMode: boolean
  onClose: () => void
  handleAssign: (f114: string) => void
}

const NominateModal: FC<IProps> = ({
  isLightMode,
  frequencyPoolTableEquipments,
  frequencyPools_113_114_115,
  isOpen,
  onClose,
  handleAssign,
}) => {
  const [f114, setF114] = useState('')

  return (
    <Modal className={styles.modal} open={isOpen} onClose={onClose}>
      <Box className={styles.modalContent}>
        <Box>
          <Box className={styles.header}>
            <Typography
              data-testid="title"
              id="enableTitle"
              className={styles.title}
            >
              Nominate Frequency
            </Typography>
            <IconButton data-testid="closeButton" onClick={onClose}>
              <CloseOutlinedIcon />
            </IconButton>
          </Box>
          <Box className={styles.content}>
            <Box className={styles.fieldContainer}>
              <Typography className={styles.label}>F 114:</Typography>
              <Select
                className={styles.field}
                onChange={(evt) => setF114(String(evt.target.value))}
              >
                {frequencyPools_113_114_115.map((elem) => (
                  <MenuItem key={elem.id} value={elem.value_114}>
                    {`${elem.value_113} / ${elem.value_114} / ${elem.value_115}`}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box className={styles.fieldContainer}>
              <Typography className={styles.label}>Antenna:</Typography>
              <Select className={styles.field}>
                {frequencyPoolTableEquipments.map((elem) => (
                  <MenuItem key={elem.id} value={elem.id}>
                    {`${elem.value_x54 ?? 'N/A'}} - ${elem.value_x62}`}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>
          <Box className={styles.actions}>
            <Button
              className={styles.addBtn}
              variant="contained"
              onClick={() => handleAssign(f114)}
            >
              Assign
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default NominateModal
