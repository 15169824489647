import { createContext, useContext, ReactNode } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import { ToastType } from '../types/toast.type'

import 'react-toastify/dist/ReactToastify.css'

const ToastContext = createContext({
  showToast: (type: ToastType, message: string, options?: object) => {},
})

export const useToast = () => useContext(ToastContext)

export const ToastProvider = ({ children }: { children: ReactNode }) => {
  const showToast = (type: ToastType, message: string, options = {}) => {
    toast[type](message, {
      ...options,
      autoClose: 1000,
    })
  }

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      <ToastContainer />
    </ToastContext.Provider>
  )
}
