import { FC } from 'react'
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material'

interface IProps {
  isLightMode: boolean
  value?: string | number | null
  className?: string
  label?: string
  items: { value: string | number; displayValue: string | number }[]
  maxWidth?: number
  defaultValue?: string | number
  placeholder?: string
  handleChange: (value: string | number | null) => void
}

export const SelectComponent: FC<IProps> = ({
  isLightMode,
  items,
  value,
  label,
  maxWidth,
  className,
  defaultValue,
  placeholder,
  handleChange,
}) => {
  return (
    <FormControl>
      <InputLabel sx={{ color: 'gray' }} id={label + ' label'}>
        {label}
      </InputLabel>
      <Select
        placeholder={placeholder}
        label={label}
        labelId={label + ' label'}
        value={value}
        defaultValue={defaultValue}
        className={className}
        onChange={(evt) => handleChange(evt.target.value)}
      >
        {items.map(({ value, displayValue }) => (
          <MenuItem value={value} sx={{ maxWidth: maxWidth || 350 }}>
            <Box
              component="span"
              sx={{
                display: 'inline-block',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '100%',
                mb: '-8px',
              }}
            >
              {displayValue}
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
