import { useContext, useState } from 'react'
import dayjs from 'dayjs'
import { useAuth0 } from '@auth0/auth0-react'
import { Box, Button, Typography } from '@mui/material'
import { IDevice } from '../../../../types/equipment.type'
import DeviceModal from '../../Modals/DeviceModal'
import TableComponent from '../../../shared/Table'
import { DevicesConfigs } from '../../../../utils/constants'
import { PlaybookContext } from '../../../../contexts/playbooks.context'
import { SpectrumManagerContext } from '../../../../contexts/spectrum.context'
import {
  createDevice,
  deleteDevice,
  updateDevice,
} from '../../../../services/spectrum-manager.service'
import { useToast } from '../../../../contexts/toast.context'

import styles from './styles.module.scss'

export const Devices = () => {
  const [device, setDevice] = useState<IDevice | null>(null)
  const [isEditing, setIsEditing] = useState(false)
  const [isCreating, setIsCreating] = useState(false)

  const { showToast } = useToast()
  const { isLightMode } = useContext(PlaybookContext)
  const { devices, setDevices } = useContext(SpectrumManagerContext)
  const { getAccessTokenSilently } = useAuth0()

  const handleDeviceRemove = async (id: number) => {
    const data = await deleteDevice(await getAccessTokenSilently(), id)

    if (data.success) {
      setDevices(devices.filter((device) => device.id !== id))
      showToast('success', 'Device is deleted successfully')
    }
  }

  const handleCreate = async () => {
    if (device) {
      const data = await createDevice(await getAccessTokenSilently(), device)

      if (data.data) {
        setDevices([...devices, data.data])
        showToast('success', 'Device is created successfully')
        setDevice(data.data)
        setIsEditing(true)
        setIsCreating(false)
      }
    }
  }

  const handleChange = async (
    key: string,
    value: string | number | dayjs.Dayjs | null,
  ) => {
    const changedDevice = device ? device : ({} as IDevice)

    setDevice({ ...changedDevice, [key]: value })
  }

  const handleSave = async () => {
    if (isCreating) {
      handleCreate()
      return
    }

    if (device) {
      const data = await updateDevice(
        await getAccessTokenSilently(),
        device.id,
        device,
      )

      if (data.data) {
        setDevices((prev) =>
          prev.map((device) =>
            data.data && device.id === data.data.id ? data.data : device,
          ),
        )
        showToast('success', 'Device is updated successfully')
        setDevice(null)
        setIsEditing(false)
        setIsCreating(false)
      }
    }
  }

  return (
    <Box className={styles.devices}>
      <Box className={styles.titleContainer}>
        <Typography className={styles.title}></Typography>
      </Box>
      <Box className={styles.tableContainer}>
        <TableComponent
          height={500}
          isLightMode={isLightMode}
          data={devices}
          configs={DevicesConfigs}
          handleRefetch={() => {}}
          handleView={(item: IDevice) => {
            setIsEditing(true)
            setDevice(item)
          }}
          handleDelete={handleDeviceRemove}
        />
        <Box className={styles.createDeviceContainer}>
          <Button
            variant="outlined"
            onClick={() => {
              setIsCreating(true)
              setDevice({} as IDevice)
            }}
          >
            Create New Device
          </Button>
        </Box>
      </Box>
      {device && (
        <DeviceModal
          device={device}
          open={isCreating || isEditing}
          isCreating={isCreating}
          handleSave={handleSave}
          fields={DevicesConfigs.items}
          onClose={() => {
            setDevice(null)
            setIsEditing(false)
            setIsCreating(false)
          }}
          handleChange={handleChange}
        />
      )}
    </Box>
  )
}
