import { FC } from 'react'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { Box, Typography, IconButton, Modal } from '@mui/material'

import styles from './styles.module.scss'

interface IProps {
  sfaf: string
  isOpen: boolean
  isLightMode: boolean
  handleChange: (sfaf: string) => void
  onClose: () => void
}

const SFAFView: FC<IProps> = ({
  sfaf,
  isLightMode,
  isOpen,
  handleChange,
  onClose,
}) => {
  return (
    <Modal className={styles.modal} open={isOpen} onClose={onClose}>
      <Box className={styles.modalContent}>
        <Box>
          <Box className={styles.header}>
            <Typography
              data-testid="title"
              id="enableTitle"
              className={styles.title}
            >
              SFAF View
            </Typography>
            <IconButton data-testid="closeButton" onClick={onClose}>
              <CloseOutlinedIcon />
            </IconButton>
          </Box>
          <textarea
            placeholder="SFAF value"
            onChange={(evt) => handleChange(evt.target.value)}
            style={{
              fontSize: 14,
              width: '100%',
              height: sfaf ? '600px' : '50px',
              color: isLightMode ? 'black' : 'white',
              backgroundColor: isLightMode ? 'white' : 'rgb(19, 34, 46)',
            }}
            value={sfaf}
          />
        </Box>
      </Box>
    </Modal>
  )
}

export default SFAFView
