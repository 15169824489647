import { useState } from 'react'
import { Box } from '@mui/material'
import { OpenFrequencyRequests } from '../OpenFrequencyRequests'
import { FrequencyPoolAssignments } from '../FrequencyPoolAssignments'

import styles from './styles.module.scss'

export const Licenses = () => {
  const [selectedOpenFreq, setSelectedOpenFreq] = useState({
    exerciseId: 0,
    end_freq_long: 0,
    start_freq_long: 0,
    assigned_freq_id: 0,
  })

  return (
    <Box className={styles.licenses}>
      <OpenFrequencyRequests setSelectedOpenFreq={setSelectedOpenFreq} />
      <FrequencyPoolAssignments selectedOpenFreq={selectedOpenFreq} />
    </Box>
  )
}
