import { Box } from '@mui/material'
import { Devices } from '../Devices'
import { Platforms } from '../Platforms'

import styles from './styles.module.scss'

export const DevicesPlatforms = () => {
  return (
    <Box className={styles.devicesPlatforms}>
      <Devices />
      <Platforms />
    </Box>
  )
}
