import { useContext, useEffect, useRef, useState } from 'react'
import { Box } from '@mui/material'
import { useAuth0 } from '@auth0/auth0-react'
import { IArcDiagramNodes } from '../../../../types/spectrum-manager.type'
import { getAllArcDiagramNodes } from '../../../../services/spectrum-manager.service'
import { SpectrumManagerContext } from '../../../../contexts/spectrum.context'

const ArcDiagramIframe = () => {
  const [sort, setSort] = useState('Group')
  const iframeRef = useRef<HTMLIFrameElement | null>(null)
  const [model, setModel] = useState<{ nodes: IArcDiagramNodes[] }>({
    nodes: [],
  })

  const { getAccessTokenSilently } = useAuth0()
  const { filterOptions } = useContext(SpectrumManagerContext)

  const getArcDiagramNodes = async () => {
    const data = await getAllArcDiagramNodes(
      await getAccessTokenSilently(),
      filterOptions,
    )

    if (data.data) {
      setModel({ nodes: data.data })
    }
  }

  useEffect(() => {
    getArcDiagramNodes()
  }, [filterOptions])

  useEffect(() => {
    const sendModelToIframe = () => {
      if (iframeRef.current && iframeRef.current.contentWindow) {
        iframeRef.current.contentWindow.postMessage({ model, sort }, '*')
      }
    }

    if (iframeRef.current) {
      if (iframeRef.current.contentWindow?.document.readyState === 'complete') {
        sendModelToIframe()
      } else {
        iframeRef.current.onload = sendModelToIframe
      }
    }
  }, [model, sort])

  // Add a handler for sort change
  const handleSortChange = (event: any) => {
    setSort(event.target.value)
    if (iframeRef.current) {
      iframeRef.current.contentWindow?.postMessage(
        { model, sort: event.target.value },
        '*',
      )
    }
  }

  return (
    <Box>
      {/* <Select value={sort} onChange={handleSortChange}>
        {['Group', 'Name', 'Degree'].map((value) => (
          <MenuItem key={value} value={value}>
            {value}
          </MenuItem>
        ))}
      </Select> */}
      <iframe
        ref={iframeRef}
        src="/arc-diagram.html"
        width="100%"
        height="800"
        style={{ border: 'none' }}
      />
    </Box>
  )
}

export default ArcDiagramIframe
