import { FC } from 'react'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { Box, Typography, IconButton, Button, Modal } from '@mui/material'

import styles from './styles.module.scss'

interface IProps {
  text: string
  btnText: string
  color: 'success' | 'error'
  onClose: () => void
  onConfirm?: () => void
}

const ConfirmationModal: FC<IProps> = ({
  text,
  btnText,
  color,
  onClose,
  onConfirm,
}) => {
  return (
    <Modal className={styles.modal} open onClose={onClose}>
      <Box className={styles.modalContent}>
        <Box>
          <Box className={styles.header}>
            <Typography data-testid="title" className={styles.title}>
              {text}
            </Typography>
            <IconButton data-testid="closeButton" onClick={onClose}>
              <CloseOutlinedIcon />
            </IconButton>
          </Box>
          <Box className={styles.actions}>
            <Button
              id="confirmButton"
              data-testid="confirmButton"
              variant="outlined"
              className={styles.removeBtn}
              onClick={onConfirm}
            >
              {btnText}
            </Button>
            <Button
              className={styles.cancelBtn}
              variant="outlined"
              onClick={onClose}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default ConfirmationModal
