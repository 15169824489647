import React, { useContext, useEffect, useRef, useState } from 'react'
import Paper from '@mui/material/Paper'
import {
  TableCell,
  Box,
  Tooltip,
  Typography,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TextField,
  Select,
  MenuItem,
  Button,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import VisibilityIcon from '@mui/icons-material/Visibility'
import EditIcon from '@mui/icons-material/Edit'
import Loader from '../Loader'
import Toolbar from '../../features/SpectrumManagerDashboard/Toolbar'
import SFAFDrawer from '../../features/SpectrumManagerDashboard/SFAFDrawer'
import TableToolbar from '../TableToolbar'
import { IPagination } from '../../../types/playbook.type'
import { TableVirtuoso } from 'react-virtuoso'
import ConfirmationModal from '../../features/Modals/ConfirmationModal'
import { PlaybookContext } from '../../../contexts/playbooks.context'
import { IExerciseRequestDetails } from '../../../types/spectrum-manager.type'
import { formatDateString, getTableCellColor } from '../../../utils/functions'

import styles from './styles.module.scss'

const colors: { [key: string]: { background: string; color: string } } = {}

interface IProps<T> {
  data: T[]
  configs: {
    title: string
    items: {
      column: string
      key: string
      isColor: boolean
      isDate?: boolean
      width?: number
      items?: any[]
    }[]
    isViewable: boolean
    isDeletable: boolean
    isSearchable?: boolean
    isNominating?: boolean
    isSelectable?: boolean
  }
  freqStatusSelect?: boolean
  height: number
  isLightMode: boolean
  totalCount?: number
  isIncomplete?: boolean
  handleRefetch: (pagination: IPagination, search?: string) => void
  isLoading?: boolean
  importSfaf?: boolean
  handleView?: (item: any) => void
  handleCreate?: () => void
  handleDelete?: (id: number) => void
  handleSelect?: (item: any) => void
  handleDownload?: () => void
  handleNominate?: (item: any) => void
  handleOpenSfaf?: () => void
  setIsCompleting?: (item: any) => void
}

const TableComponent = <T extends Record<string, any>>({
  data,
  height,
  configs,
  isLoading,
  totalCount,
  importSfaf,
  handleView,
  handleCreate,
  handleSelect,
  handleDelete,
  isIncomplete,
  handleRefetch,
  handleNominate,
  handleDownload,
  handleOpenSfaf,
  setIsCompleting,
  freqStatusSelect,
}: IProps<T>) => {
  const [page, setPage] = useState(0)
  const [isDeleting, setIsDeleting] = useState(0)
  const [search, setSearch] = useState('')
  const [filteredData, setFilteredData] = useState<T[]>([])
  const [selectedRow, setSelectedRow] = useState(0)
  const [request, setRequest] = useState<IExerciseRequestDetails | null>(null)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [sortConfig, setSortConfig] = useState<{
    key: string
    direction: 'asc' | 'desc'
  } | null>(null)
  const { isLightMode } = useContext(PlaybookContext)

  const scrollContainerRef = useRef<HTMLDivElement | null>(null)

  const handleSelectRow = (elem: any) => {
    if (configs.title === 'Requests') {
      setSelectedRow(elem.request_id_inner)
    } else {
      setSelectedRow(elem.id)
    }

    if (handleSelect) handleSelect(elem)
  }

  const onPageChange = (page: number) => {
    setPage(page)
    handleRefetch({
      count: totalCount || 0,
      take: rowsPerPage,
      skip: page * rowsPerPage,
    })
  }

  const handleSearch = (term: string) => {
    const searchResults = data.filter((elem) =>
      Object.values(elem).some((value) =>
        String(value).toLocaleLowerCase().includes(term.toLowerCase()),
      ),
    )
    setSearch(term)
    setFilteredData(
      sortConfig
        ? sortData(sortConfig.key, sortConfig.direction, searchResults)
        : searchResults,
    )
  }

  const onRowsPerPageChange = (rows: number) => {
    setPage(0)
    setRowsPerPage(rows)
    handleRefetch({
      count: totalCount || 0,
      take: rows,
      skip: 0,
    })
  }

  const handleSort = (key: string) => {
    const direction =
      sortConfig?.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc'

    const scrollLeft = scrollContainerRef.current?.scrollLeft || 0

    setSortConfig({ key, direction })
    setFilteredData(sortData(key, direction, filteredData))

    requestAnimationFrame(() => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollLeft = scrollLeft
      }
    })
  }

  const sortData = (
    key: string,
    direction: 'asc' | 'desc',
    dataToSort: T[],
  ) => {
    return [...dataToSort].sort((a, b) => {
      if (a[key] < b[key]) return direction === 'asc' ? -1 : 1
      if (a[key] > b[key]) return direction === 'asc' ? 1 : -1
      return 0
    })
  }

  useEffect(() => {
    setFilteredData(data)
  }, [data])

  const rowContent = (_index: number, elem: T) => {
    return (
      <>
        {configs.isNominating && (
          <td
            align="center"
            style={{
              width: '50px',
              borderColor: isLightMode ? 'black' : 'white',
            }}
          >
            <IconButton onClick={() => handleNominate && handleNominate(elem)}>
              <AddIcon />
            </IconButton>
          </td>
        )}
        {configs.isViewable && (
          <td
            align="center"
            style={{
              width: '50px',
              borderColor: isLightMode ? 'black' : 'white',
            }}
          >
            <IconButton
              onClick={(evt) => {
                evt.stopPropagation()
                setRequest(elem as any)
                if (handleView) handleView(elem)
              }}
            >
              <VisibilityIcon />
            </IconButton>
            {isIncomplete && (
              <IconButton
                onClick={(evt) => {
                  evt.stopPropagation()
                  if (setIsCompleting) setIsCompleting(elem as any)
                }}
              >
                <EditIcon />
              </IconButton>
            )}
          </td>
        )}
        {configs.items.map(({ key, isColor, isDate, width }) => {
          let color = { background: '', color: '' }
          const rowBackgroundColor = (
            configs.title === 'Requests'
              ? selectedRow === elem.request_id_inner
              : selectedRow === elem.id
          )
            ? isLightMode
              ? 'rgba(0, 0, 0, 0.1)'
              : 'rgba(255, 255, 255, 0.2)'
            : 'transparent'

          if (isColor) {
            const value = elem[key]
            color = getTableCellColor(colors, value, isLightMode)
            if (!colors[value]) colors[value] = color
          }

          return (
            <td
              key={key}
              className={styles.bodyCell}
              onClick={
                configs.isSelectable ? () => handleSelectRow(elem) : undefined
              }
              style={{
                width: width || 150,
                height: 50,
                cursor: configs.isSelectable ? 'pointer' : 'unset',
                padding: '0 10px',
                background: rowBackgroundColor,
                borderColor: isLightMode ? 'black' : 'white',
              }}
            >
              <Tooltip title={String(elem[key])}>
                {isColor ? (
                  <Box
                    sx={{ backgroundColor: color.background }}
                    className={isColor && elem[key] ? styles.coloredCell : ''}
                  >
                    <Typography sx={{ color: color.color }}>
                      {isDate ? formatDateString(elem[key]) : elem[key]}
                    </Typography>
                  </Box>
                ) : (
                  <>{isDate ? formatDateString(elem[key]) : elem[key]}</>
                )}
              </Tooltip>
            </td>
          )
        })}
        {configs.isDeletable && (
          <td
            style={{
              width: '100px',
              borderColor: isLightMode ? 'black' : 'white',
            }}
            align="center"
          >
            <IconButton onClick={() => setIsDeleting(elem.id)}>
              <DeleteIcon sx={{ color: 'red' }} />
            </IconButton>
          </td>
        )}
      </>
    )
  }

  const fixedHeaderContent = () => (
    <TableRow sx={{ background: isLightMode ? 'white' : 'rgb(19, 34, 46)' }}>
      {configs.isNominating && (
        <th
          style={{
            width: '100px',
            borderColor: isLightMode ? 'black' : 'white',
          }}
        ></th>
      )}
      {configs.isViewable && (
        <th
          style={{
            width: '100px',
            borderColor: isLightMode ? 'black' : 'white',
          }}
        >
          <Typography>
            {configs.title === 'Requests' ? 'View SFAF' : ''}
          </Typography>
        </th>
      )}
      {configs.items.map(({ column, key, width }) => (
        <th
          key={key}
          style={{
            width: width || 150,
            borderColor: isLightMode ? 'black' : 'white',
            cursor: 'pointer',
          }}
          onClick={() => handleSort(key)}
        >
          <Tooltip title={column}>
            <Typography>
              {column}
              {sortConfig?.key === key && (
                <span>{sortConfig.direction === 'asc' ? ' ⬆' : ' ⬇'}</span>
              )}
            </Typography>
          </Tooltip>
        </th>
      ))}
      {configs.isDeletable && (
        <th
          style={{
            width: '100px',
            borderColor: isLightMode ? 'black' : 'white',
          }}
        ></th>
      )}
    </TableRow>
  )

  return (
    <Box className={styles.tableComponent}>
      <Box className={styles.titleContainer}>
        <Typography className={styles.title}>{configs.title}</Typography>
        {handleCreate && (
          <Button variant="outlined" onClick={handleCreate}>
            Create
          </Button>
        )}
        {configs.title === 'Requests' && (
          <Toolbar tabs={[]} value={0} setValue={() => {}} isDashboard />
        )}
      </Box>
      {configs.isSearchable && (
        <Box className={styles.searchContainer}>
          <TextField
            className={styles.search}
            sx={{ maxWidth: importSfaf || freqStatusSelect ? 'auto' : 300 }}
            value={search}
            label="Search"
            onChange={(evt) => handleSearch(evt.target.value)}
          />
          {importSfaf && (
            <Button
              className={styles.importBtn}
              variant="outlined"
              onClick={handleOpenSfaf}
            >
              Import
            </Button>
          )}
          {freqStatusSelect && (
            <Select
              defaultValue={'NOT_STARTED'}
              className={styles.select}
              onChange={(evt) =>
                handleRefetch({} as IPagination, String(evt.target.value))
              }
            >
              {['NOT_STARTED', 'ACCEPTED', 'APPROVED', 'DENIED'].map(
                (status) => (
                  <MenuItem key={status} value={status}>
                    {status}
                  </MenuItem>
                ),
              )}
            </Select>
          )}
        </Box>
      )}

      {!isLoading && (
        <TableContainer
          ref={scrollContainerRef}
          sx={{ overflowX: 'auto', maxHeight: height }}
        >
          <TableVirtuoso
            data={filteredData}
            components={{
              Scroller: React.forwardRef((props: any, ref) => (
                <TableContainer component={Paper} {...props} ref={ref} />
              )),
              Table: (props: any) => (
                <Table
                  {...props}
                  sx={{
                    borderCollapse: 'separate',
                    tableLayout: 'fixed',
                    width: '100%',
                  }}
                />
              ),
              TableHead: React.forwardRef((props: any, ref) => (
                <TableHead {...props} ref={ref} />
              )),
              TableRow,
              TableBody: React.forwardRef((props: any, ref) => (
                <TableBody {...props} ref={ref} />
              )),
            }}
            fixedHeaderContent={fixedHeaderContent}
            itemContent={rowContent}
            style={{ width: '100%', height }}
            totalCount={data.length}
          />
        </TableContainer>
      )}
      {isLoading && (
        <Box sx={{ width: '100%' }}>
          <Loader />
        </Box>
      )}
      {totalCount && (
        <TableToolbar
          isLightMode={isLightMode}
          pagination={{ skip: page, take: rowsPerPage, count: totalCount }}
          onPageChange={onPageChange}
          handleDownload={handleDownload}
          handleRefresh={() =>
            handleRefetch({
              count: totalCount || 0,
              take: rowsPerPage,
              skip: page * rowsPerPage,
            })
          }
          onRowsPerPageChange={onRowsPerPageChange}
        />
      )}
      {configs.title === 'Requests' && request && (
        <SFAFDrawer
          isOpen={!!request}
          request={request}
          handleClose={() => setRequest(null)}
        />
      )}
      {!!isDeleting && (
        <ConfirmationModal
          color="error"
          text="Are you sure you want to delete this row"
          btnText="Delete"
          onConfirm={() => {
            setIsDeleting(0)
            handleDelete && handleDelete(isDeleting)
          }}
          onClose={() => setIsDeleting(0)}
        />
      )}
    </Box>
  )
}

export default TableComponent
