import {
  FC,
  useRef,
  useEffect,
  useContext,
  MouseEvent,
  useCallback,
} from 'react'
import {
  Edge,
  Node,
  getIncomers,
  getOutgoers,
  useReactFlow,
  getConnectedEdges,
} from '@xyflow/react'
import { PlaybookContext } from '../../../../contexts/playbooks.context'

import styles from './styles.module.scss'

interface IProps {
  id: string
  top: number | boolean
  left: number | boolean
  right: number | boolean
  bottom: number | boolean
  onClick: () => void
}

const ContextMenu: FC<IProps> = ({ id, top, left, right, bottom, onClick }) => {
  const { setNodes, setEdges } = useReactFlow()
  const { playbook, nodes, edges } = useContext(PlaybookContext)

  const modalRef = useRef<HTMLDivElement>(null)

  const deleteNode = useCallback(async () => {
    if (playbook) {
      const deletedNode = nodes.find((node) => node.id === id)

      if (deletedNode) {
        setNodes((nodes: Node[]) =>
          nodes.filter((node: Node) => node.id !== id),
        )

        setEdges(
          [deletedNode].reduce((acc: Edge[], node: Node) => {
            const incomers = getIncomers(node, nodes, edges)
            const outgoers = getOutgoers(node, nodes, edges)
            const connectedEdges = getConnectedEdges([node], edges)

            const remainingEdges = acc.filter(
              (edge) => !connectedEdges.includes(edge),
            )

            const createdEdges = incomers.flatMap(({ id: source }) =>
              outgoers.map(({ id: target }) => ({
                id: `${source}->${target}`,
                source,
                target,
              })),
            )

            return [...remainingEdges, ...createdEdges]
          }, edges),
        )
      }
    }
  }, [id, setNodes, setEdges])

  const handleClickOutside = (event: Event) => {
    if (modalRef.current && !modalRef.current.contains(event.target as any)) {
      onClick()
    }
  }

  const handleDelete = (evt: MouseEvent) => {
    evt.stopPropagation()

    deleteNode()
    onClick()
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div
      ref={modalRef}
      //@ts-ignore
      style={{ top, left, right, bottom }}
      className={styles.contextMenu}
      onClick={onClick}
    >
      <p style={{ margin: '0.5em' }}>
        <small>node: {id}</small>
      </p>
      <button onClick={handleDelete}>delete</button>
    </div>
  )
}

export default ContextMenu
