import { FC, useState } from 'react'
import Sections from '../../ExerciseRequestForm/Sections'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { IExerciseRequestDetails } from '../../../../types/spectrum-manager.type'
import { Box, Typography, IconButton, Modal } from '@mui/material'

import styles from './styles.module.scss'

interface IProps {
  isOpen: boolean
  onClose: () => void
  isLightMode: boolean
  detailedExerciseRequest: IExerciseRequestDetails
}

const ERFModal: FC<IProps> = ({ isLightMode, isOpen, onClose }) => {
  const [value, setValue] = useState(0)

  return (
    <Modal className={styles.modal} open={isOpen} onClose={onClose}>
      <Box className={styles.modalContent}>
        <Box className={styles.content}>
          <Box
            className={styles.header}
            sx={{
              borderBottom: isLightMode ? '1px solid gray' : '1px solid white',
            }}
          >
            <Typography className={styles.title} id="title"></Typography>
            <IconButton onClick={onClose}>
              <CloseOutlinedIcon />
            </IconButton>
          </Box>
          <Box className={styles.content}>
            <Sections
              isLightMode={isLightMode}
              value={value}
              setValue={setValue}
            />
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default ERFModal
