import { FC, useContext, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import TableComponent from '../../../shared/Table'
import { PlaybookContext } from '../../../../contexts/playbooks.context'
import { SpectrumManagerContext } from '../../../../contexts/spectrum.context'
import { ExerciseRequestTableConfigs } from '../../../../utils/constants'
import { IPagination } from '../../../../types/playbook.type'
import { IExerciseRequestDetails } from '../../../../types/spectrum-manager.type'
import ERFModal from '../../Modals/ERFModal'
import { useAuth0 } from '@auth0/auth0-react'
import { ExerciseRequestContext } from '../../../../contexts/exercise-request.context'
import { getSingleExerciseRequest } from '../../../../services/exercise-request-form.service'
import { ExerciseRequestEquipmentContext } from '../../../../contexts/exercise-request-equipment.context'

import styles from './styles.module.scss'

interface IProps {
  onRequestSelect: (request: IExerciseRequestDetails) => void
}

const Requests: FC<IProps> = ({ onRequestSelect }) => {
  const [completingItem, setCompletingItem] =
    useState<IExerciseRequestDetails | null>(null)

  const {
    detailedExerciseRequests,
    setDetailedExerciseRequests,
    getDetailedExerciseRequests,
  } = useContext(SpectrumManagerContext)

  const {
    selectedExerciseRequest,
    selectedExerciseRequestLocation,
    selectedExerciseRequestFrequency,
  } = useContext(ExerciseRequestContext)
  const { equipment } = useContext(ExerciseRequestEquipmentContext)

  const { getAccessTokenSilently } = useAuth0()
  const { setSelectedExerciseRequest } = useContext(ExerciseRequestContext)

  const { isLightMode } = useContext(PlaybookContext)

  const getExerciseRequest = async () => {
    if (completingItem) {
      const data = await getSingleExerciseRequest(
        await getAccessTokenSilently(),
        completingItem.request_id_inner,
      )

      if (data.data) {
        setSelectedExerciseRequest(data.data)
      }
    }
  }

  useEffect(() => {
    getExerciseRequest()
  }, [completingItem])

  useEffect(() => {
    getDetailedExerciseRequests()
  }, [
    equipment,
    selectedExerciseRequest,
    selectedExerciseRequestLocation,
    selectedExerciseRequestFrequency,
  ])

  return (
    <Box
      className={styles.requests}
      style={{ backgroundColor: isLightMode ? 'white' : '#13222e' }}
    >
      <TableComponent
        isIncomplete={true}
        height={550}
        isLightMode={isLightMode}
        handleSelect={(request: IExerciseRequestDetails) =>
          onRequestSelect(request)
        }
        isLoading={detailedExerciseRequests.isLoading}
        data={detailedExerciseRequests.requests}
        setIsCompleting={(item: IExerciseRequestDetails) =>
          setCompletingItem(item)
        }
        totalCount={detailedExerciseRequests.pagination.count}
        configs={ExerciseRequestTableConfigs}
        handleRefetch={(pagination: IPagination) =>
          setDetailedExerciseRequests((prev) => ({ ...prev, pagination }))
        }
      />
      {completingItem && (
        <ERFModal
          isOpen={!!completingItem}
          onClose={() => setCompletingItem(null)}
          isLightMode={isLightMode}
          detailedExerciseRequest={completingItem}
        />
      )}
    </Box>
  )
}

export default Requests
