import { useContext, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { NavLink } from 'react-router-dom'
import Logout from '@mui/icons-material/Logout'
import DarkModeIcon from '@mui/icons-material/DarkMode'
import LightModeIcon from '@mui/icons-material/LightMode'
import {
  Box,
  Menu,
  Tooltip,
  Divider,
  MenuItem,
  IconButton,
  ListItemIcon,
} from '@mui/material'
import Logo from '../../../assets/spectrum-logo.png'
import { HeaderLinks } from '../../../utils/constants'
import { PlaybookContext } from '../../../contexts/playbooks.context'

import styles from './styles.module.scss'

const linkClassName = (active: boolean, isLightMode: boolean) => {
  if (active && isLightMode) {
    return styles.activeLight
  } else if (active && !isLightMode) {
    return styles.activeDark
  } else if (!active && isLightMode) {
    return styles.light
  } else {
    return styles.dark
  }
}

const Header = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { isLightMode, setIsLightMode, playbook, handleUpdate } =
    useContext(PlaybookContext)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setIsOpen(true)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setIsOpen(false)
  }
  const { user, logout } = useAuth0()

  const toggleMode = () => {
    window.localStorage.setItem('mode', isLightMode ? 'dark' : 'light')
    setIsLightMode(!isLightMode)
    if (playbook) handleUpdate(playbook)
  }

  const logoutWithRedirect = () => {
    setIsOpen(false)
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    })
  }

  if (!user) {
    return null
  }

  return (
    <Box
      className={styles.header}
      data-testid="header"
      style={{ backgroundColor: isLightMode ? 'rgb(22, 119, 56)' : '#13222e' }}
    >
      <Box className={styles.links}>
        <img src={Logo} className={styles.logo} />
        {HeaderLinks.map(({ url, title }) => (
          <NavLink
            className={({ isActive }) => linkClassName(isActive, isLightMode)}
            key={title}
            to={url}
          >
            {title}
          </NavLink>
        ))}
      </Box>
      <Box className={styles.rightSide} style={{ display: 'flex' }}>
        <IconButton
          className={styles.themeModeBtn}
          style={{
            border: '1px solid',
            borderColor: isLightMode ? 'white' : 'yellow',
          }}
          onClick={toggleMode}
        >
          {isLightMode ? (
            <DarkModeIcon sx={{ color: 'white' }} />
          ) : (
            <LightModeIcon sx={{ color: 'yellow' }} />
          )}
        </IconButton>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <img
              src={user.picture}
              alt="Profile"
              className="nav-user-profile rounded-circle"
              width="40"
            />
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={isOpen}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem onClick={handleClose} style={{ gap: 10 }}>
            <img
              src={user.picture}
              alt="Profile"
              className="nav-user-profile rounded-circle"
              width="50"
            />{' '}
            {user.name}
          </MenuItem>
          <Divider />
          <MenuItem onClick={logoutWithRedirect}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </Box>
    </Box>
  )
}

export default Header
