import {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'
import {
  Box,
  Button,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { useAuth0 } from '@auth0/auth0-react'
import ConfirmationModal from '../../Modals/ConfirmationModal'
import { IExerciseRequest } from '../../../../types/exercise.type'
import { IClassificationType } from '../../../../types/classifications.type'
import { ExerciseRequestContext } from '../../../../contexts/exercise-request.context'
import {
  IOperatingUnit,
  IOperatingSubUnit,
} from '../../../../types/operating-unit.types'
import {
  getAllOperatingUnits,
  updateExerciseRequest,
  getAllOperatingSubUnits,
  deleteExerciseRequestById,
  getAllClassificationTypes,
} from '../../../../services/exercise-request-form.service'
import { useToast } from '../../../../contexts/toast.context'
import { SelectComponent } from '../../../shared/SelectComponent'
import { SelectWithSearch } from '../../../shared/SearchSelect'

import styles from './styles.module.scss'

interface IProps {
  isLightMode: boolean
  setValue: Dispatch<SetStateAction<number>>
  selectedExerciseRequest: IExerciseRequest
}

const GeneralSection: FC<IProps> = ({
  isLightMode,
  setValue,
  selectedExerciseRequest,
}) => {
  const { getAccessTokenSilently } = useAuth0()
  const {
    exercises,
    setSelectedExerciseRequest,
    getExerciseRequests,
    setExerciseRequests,
  } = useContext(ExerciseRequestContext)

  const [isDelete, setIsDelete] = useState(false)
  const [name, setName] = useState(selectedExerciseRequest.username)
  const [phone, setPhone] = useState(selectedExerciseRequest.user_phone)
  const [manager, setManager] = useState('')
  const [exercise, setExercise] = useState(selectedExerciseRequest.exercise_id)
  const [shortTitle, setShortTitle] = useState(selectedExerciseRequest.name)
  const [subAccount, setSubAccount] = useState(
    selectedExerciseRequest.operating_sub_unit_id,
  )
  const [subAccounts, setSubAccounts] = useState<IOperatingSubUnit[]>([])
  const [operatingUnit, setOperatingUnit] = useState(
    selectedExerciseRequest.operating_unit_id,
  )
  const [operatingUnits, setOperatingUnits] = useState<IOperatingUnit[]>([])
  const [classificationType, setClassificationType] = useState(
    selectedExerciseRequest.classification_type_id,
  )
  const [classificationTypes, setClassificationTypes] = useState<
    IClassificationType[]
  >([])

  const { showToast } = useToast()

  const handleClose = () => {
    setIsDelete(false)
  }

  const handleSave = async () => {
    if (exercise && phone && name && operatingUnit) {
      const data = await updateExerciseRequest(
        await getAccessTokenSilently(),
        selectedExerciseRequest.id,
        phone,
        exercise,
        shortTitle,
        operatingUnit,
        subAccount,
        classificationType,
      )

      if (data.success && data.data) {
        setExerciseRequests((prev) =>
          prev.map((elem) =>
            elem.id === selectedExerciseRequest.id && data.data
              ? data.data
              : elem,
          ),
        )
        setSelectedExerciseRequest(data.data)
        handleClose()
        showToast('success', 'Exercise Request successfully updated')
      }
    } else {
      showToast('error', 'Please fill out all required fields')
    }
  }

  const handleDelete = async () => {
    const data = await deleteExerciseRequestById(
      await getAccessTokenSilently(),
      selectedExerciseRequest.id,
    )

    if (data.success) {
      setExerciseRequests((prev) =>
        prev.filter((elem) => elem.id !== selectedExerciseRequest.id),
      )
      setSelectedExerciseRequest(null)
      showToast('success', data.message)
      handleClose()
    }
  }

  const getOperatingUnits = async () => {
    const data = await getAllOperatingUnits(await getAccessTokenSilently())

    if (data.success && data.data) {
      setOperatingUnits(data.data)
    }
  }

  const getOperatingSubUnits = async () => {
    const data = await getAllOperatingSubUnits(
      await getAccessTokenSilently(),
      operatingUnit,
    )

    if (data.success && data.data) {
      setSubAccounts(data.data)
    }
  }

  const getClassificationTypes = async () => {
    const data = await getAllClassificationTypes(await getAccessTokenSilently())

    if (data.success && data.data) {
      setClassificationTypes(data.data)
    }
  }

  useEffect(() => {
    if (operatingUnit) {
      const chosenOperatingUnit = operatingUnits.find(
        (elem) => elem.id === operatingUnit,
      )

      if (chosenOperatingUnit) {
        setManager(chosenOperatingUnit.manager)
      }

      getOperatingSubUnits()
    }
  }, [operatingUnit])

  useEffect(() => {
    setName(selectedExerciseRequest.username)
    setPhone(selectedExerciseRequest.user_phone)
    setExercise(selectedExerciseRequest.exercise_id)
    setShortTitle(selectedExerciseRequest.name)
    setSubAccount(selectedExerciseRequest.operating_sub_unit_id)
    setOperatingUnit(selectedExerciseRequest.operating_unit_id)
    setClassificationType(selectedExerciseRequest.classification_type_id)
  }, [selectedExerciseRequest])

  useEffect(() => {
    getOperatingUnits()
    getClassificationTypes()
  }, [])

  return (
    <Box
      className={styles.generalSection}
      style={{
        backgroundColor: isLightMode ? 'white' : '#13222e',
      }}
    >
      <Box className={styles.actions}>
        <Button
          className={styles.saveBtn}
          variant="contained"
          onClick={() => handleSave()}
          style={isLightMode ? {} : { backgroundColor: 'green' }}
        >
          Save
        </Button>
        <Button
          className={styles.deleteBtn}
          variant="contained"
          onClick={() => setIsDelete(true)}
          style={isLightMode ? {} : { backgroundColor: 'red' }}
        >
          Delete
        </Button>
      </Box>
      <Box className={styles.sectionContainer}>
        <Box className={styles.titleContainer}>
          <Typography className={styles.title}>Requestor</Typography>
        </Box>
        <Box className={styles.section}>
          <Box className={styles.fieldContainer}>
            <Typography className={styles.label}>
              Name<Typography className={styles.asterisk}>*</Typography>:
            </Typography>
            <TextField
              value={name}
              onChange={(evt) => setName(evt.target.value)}
              className={styles.field}
              placeholder="Enter Value"
            />
          </Box>
          <Box className={styles.fieldContainer}>
            <Typography className={styles.label}>
              Phone<Typography className={styles.asterisk}>*</Typography>:
            </Typography>
            <TextField
              className={styles.field}
              value={phone}
              onChange={(evt) => setPhone(evt.target.value)}
              placeholder="Enter Value"
            />
          </Box>
        </Box>
      </Box>
      <Box className={styles.sectionContainer}>
        <Box className={styles.titleContainer}>
          <Typography className={styles.title}>Request For</Typography>
        </Box>
        <Box className={styles.section}>
          <Box className={styles.fieldContainer}>
            <Typography className={styles.label}>
              Exercise<Typography className={styles.asterisk}>*</Typography>:
            </Typography>
            <Select
              className={styles.field}
              value={exercise}
              onChange={(evt) => setExercise(Number(evt.target.value))}
            >
              {exercises.map((exercise) => (
                <MenuItem key={exercise.id} value={exercise.id}>
                  {exercise.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box className={styles.fieldContainer}>
            <Typography className={styles.label}>Request Name:</Typography>
            <TextField
              value={shortTitle}
              className={styles.field}
              onChange={(evt) => setShortTitle(evt.target.value)}
              placeholder="Enter Value"
            />
          </Box>
          <Box className={styles.fieldContainer}>
            <Typography className={styles.label}>
              Operating Unit
              <Typography className={styles.asterisk}>*</Typography>:
            </Typography>
            <SelectWithSearch
              width={300}
              value={operatingUnits
                .map((operatingUnit) => ({
                  label: operatingUnit.unit_name,
                  value: operatingUnit.id,
                }))
                .find((elem) => elem.value === operatingUnit)}
              label="Unit"
              handleChange={(value) => setOperatingUnit(Number(value))}
              options={operatingUnits.map((operatingUnit) => ({
                value: operatingUnit.id,
                label: operatingUnit.unit_name,
              }))}
            />
          </Box>
          <Box className={styles.fieldContainer}>
            <Typography className={styles.label}>Subaccount:</Typography>
            <SelectComponent
              isLightMode={isLightMode}
              label="Sub Account"
              value={subAccount}
              className={styles.field}
              handleChange={(value) => setSubAccount(Number(value))}
              items={subAccounts.map((elem) => ({
                value: elem.id,
                displayValue: elem.name,
              }))}
            />
          </Box>
          <Box className={styles.fieldContainer}>
            <Typography className={styles.label}>Manager:</Typography>
            <TextField
              value={manager}
              label="Manager"
              className={styles.field}
              disabled
            />
          </Box>
        </Box>
      </Box>
      <Box className={styles.sectionContainer}>
        <Box className={styles.titleContainer}>
          <Typography className={styles.title}>Releasability</Typography>
        </Box>
        <Box className={styles.section}>
          <Box className={styles.releasabilityContainer}>
            <SelectWithSearch
              value={classificationTypes
                .map((classificationType) => ({
                  label: classificationType.name,
                  value: classificationType.id,
                }))
                .find((elem) => elem.value === classificationType)}
              label="Unit"
              handleChange={(value) => setOperatingUnit(Number(value))}
              options={classificationTypes.map((classificationType) => ({
                value: classificationType.id,
                label: classificationType.name,
              }))}
            />
            <Typography>
              Not releasable outside the US Government IAW Section 552 (b)(1) of
              Title 5 of the US Code.
            </Typography>
          </Box>
        </Box>
      </Box>
      <Button
        variant="contained"
        onClick={() => {
          setValue(1)
          handleSave()
        }}
        style={isLightMode ? {} : { backgroundColor: 'green' }}
      >
        Next
      </Button>
      {isDelete && (
        <ConfirmationModal
          onClose={handleClose}
          color={'error'}
          onConfirm={handleDelete}
          btnText={'Delete'}
          text={'Are you sure you want to delete this exercise request'}
        />
      )}
    </Box>
  )
}

export default GeneralSection
