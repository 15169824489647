import React, { useState } from 'react'
import { Box, Button, Typography } from '@mui/material'

interface IProps {
  isLightMode: boolean
  setParsedData: (parsedData: FieldData[]) => void
}

interface FieldData {
  [key: string]: string
}

const FileProcessor: React.FC<IProps> = ({ isLightMode, setParsedData }) => {
  const [file, setFile] = useState<File | null>(null)
  const [fileContent, setFileContent] = useState<string>('')

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = e.target.files ? e.target.files[0] : null
    setFile(uploadedFile)
    setFileContent('')
  }

  const handleProcess = () => {
    if (!file) return

    const reader = new FileReader()
    reader.onload = (event) => {
      const content = event.target?.result as string
      setFileContent(content)

      setParsedData(parseFileContent(content))
    }
    reader.readAsText(file)
  }

  const parseFileContent = (content: string): FieldData[] => {
    const lines = content.split('\n').map((line) => line.trim())
    const data: FieldData[] = []
    let currentObject: FieldData = {}

    lines.forEach((line) => {
      if (line.match(/^\d{3}\./)) {
        const [key, value] = line.split(/\. (.+)/)

        if (key && value) {
          const fieldKey = `field_${key.trim()}`

          if (currentObject[fieldKey] !== undefined) {
            data.push(currentObject)
            currentObject = {}
          }

          currentObject[fieldKey] = value.trim()
        }
      }
    })

    if (Object.keys(currentObject).length > 0) {
      data.push(currentObject)
    }

    return data
  }

  return (
    <Box sx={{ maxWidth: '100%' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 2 }}>
        <input
          type="file"
          accept=".txt"
          id="file-upload"
          style={{ display: 'none' }}
          onChange={handleFileUpload}
        />
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', mb: 2 }}>
          <label htmlFor="file-upload" style={{ marginBottom: 0 }}>
            <Button variant="outlined" component="span">
              Upload File
            </Button>
          </label>
          {file && (
            <Button variant="outlined" onClick={handleProcess}>
              Process File
            </Button>
          )}
        </Box>
      </Box>
      {file && (
        <Typography variant="body1" sx={{ mt: 2 }}>
          Selected File: {file.name}
        </Typography>
      )}
      {fileContent && (
        <Typography variant="body1" sx={{ mt: 2, color: 'green' }}>
          File processed successfully!
        </Typography>
      )}
      <textarea
        style={{
          fontSize: 14,
          width: '100%',
          height: fileContent ? '400px' : '50px',
          color: isLightMode ? 'black' : 'white',
          backgroundColor: isLightMode ? 'white' : 'rgb(19, 34, 46)',
        }}
        value={fileContent}
        placeholder="Uploaded file content will appear here..."
      />
    </Box>
  )
}

export default FileProcessor
