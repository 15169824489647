import { axiosInstance } from './axios.service'
import { IResponse } from '../types/response.type'
import { ExerciseRequestFormEndpoints } from '../types/endpoint.type'
import {
  IExercise,
  IUsageTime,
  IStateRegion,
  IMajorFunction,
  IExerciseRequest,
  ILocationTemplate,
  IDetailedFunction,
  IUsageTimeService,
  IFrequencyTemplate,
  IIntermediateFunction,
  IExerciseRequestLocation,
  IExerciseRequestFrequency,
  IExerciseRequestFrequencySingle,
} from '../types/exercise.type'
import { IClassificationType } from '../types/classifications.type'
import {
  IOperatingSubUnit,
  IOperatingUnit,
} from '../types/operating-unit.types'

export const getAllExercises = async (
  token: string,
): Promise<IResponse<IExercise[]>> => {
  try {
    const data = await axiosInstance.get(
      ExerciseRequestFormEndpoints.GET_EXERCISES,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const getSingleExerciseRequest = async (
  token: string,
  id: number,
): Promise<IResponse<IExerciseRequest>> => {
  try {
    const data = await axiosInstance.get(
      `${ExerciseRequestFormEndpoints.GET_EXERCISE_REQUESTS}/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const getAllExerciseRequests = async (
  token: string,
  exerciseType: number | string,
  exerciseId: number | null,
): Promise<IResponse<IExerciseRequest[]>> => {
  try {
    const data = await axiosInstance.get(
      `${ExerciseRequestFormEndpoints.GET_EXERCISE_REQUESTS}?type=${exerciseType}${exerciseId ? `&exercise_id=${exerciseId}` : ''}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const getAllOperatingUnits = async (
  token: string,
): Promise<IResponse<IOperatingUnit[]>> => {
  try {
    const data = await axiosInstance.get(
      ExerciseRequestFormEndpoints.GET_OPERATING_UNITS,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const getAllOperatingSubUnits = async (
  token: string,
  operating_unit: number,
): Promise<IResponse<IOperatingSubUnit[]>> => {
  try {
    const data = await axiosInstance.get(
      `${ExerciseRequestFormEndpoints.GET_OPERATING_SUB_UNITS}/${operating_unit}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const getAllClassificationTypes = async (
  token: string,
): Promise<IResponse<IClassificationType[]>> => {
  try {
    const data = await axiosInstance.get(
      ExerciseRequestFormEndpoints.GET_CLASSIFICATION_TYPES,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const createExerciseRequest = async (
  token: string,
  userId: number,
  fullName: string,
  phone?: string,
  exerciseId?: number,
): Promise<IResponse<IExerciseRequest>> => {
  try {
    const data = await axiosInstance.post(
      `${ExerciseRequestFormEndpoints.EXERCISE_REQUEST}`,
      {
        phone,
        userId,
        fullName,
        exerciseId: exerciseId || null,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const updateExerciseRequest = async (
  token: string,
  id: number,
  phone: string,
  exercise: number,
  shortTitle: string,
  operatingUnit: number,
  operatingSubUnit: number | null,
  classificationType: number,
): Promise<IResponse<IExerciseRequest>> => {
  try {
    const data = await axiosInstance.put(
      `${ExerciseRequestFormEndpoints.EXERCISE_REQUEST}/${id}`,
      {
        phone,
        exercise,
        shortTitle,
        operatingUnit,
        operatingSubUnit,
        classificationType,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const deleteExerciseRequestById = async (
  token: string,
  id: number,
): Promise<IResponse<null>> => {
  try {
    const data = await axiosInstance.delete(
      `${ExerciseRequestFormEndpoints.EXERCISE_REQUEST}/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const getAllLocations = async (
  token: string,
  id: number,
): Promise<IResponse<IExerciseRequestLocation[]>> => {
  try {
    const data = await axiosInstance.get(
      `${ExerciseRequestFormEndpoints.EXERCISE_REQUEST_LOCATIONS}/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const createExerciseLocation = async (
  token: string,
  id: number,
  userId: number,
): Promise<IResponse<IExerciseRequestLocation>> => {
  try {
    const data = await axiosInstance.post(
      ExerciseRequestFormEndpoints.EXERCISE_REQUEST_LOCATIONS,
      {
        userId,
        exerciseRequestId: id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const updateLocationById = async (
  token: string,
  id: number,
  params: {
    rad?: number
    lon_EW?: string
    lat_NS?: string
    lon_deg?: number
    lon_min?: number
    lon_sec?: number
    lat_deg?: number
    lat_min?: number
    lat_sec?: number
    elevation?: number
    loc_template?: number
    transmitter_loc?: string
    state_region_id?: number
  },
): Promise<IResponse<IExerciseRequestLocation>> => {
  try {
    const data = await axiosInstance.put(
      `${ExerciseRequestFormEndpoints.EXERCISE_REQUEST_LOCATIONS}/${id}`,
      params,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const deleteLocationById = async (
  token: string,
  id: number,
): Promise<IResponse<null>> => {
  try {
    const data = await axiosInstance.delete(
      `${ExerciseRequestFormEndpoints.EXERCISE_REQUEST_LOCATIONS}/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const getAllLocationTemplates = async (
  token: string,
): Promise<IResponse<ILocationTemplate[]>> => {
  try {
    const data = await axiosInstance.get(
      ExerciseRequestFormEndpoints.GET_LOCATION_TEMPLATES,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const getAllStateRegions = async (
  token: string,
): Promise<IResponse<IStateRegion[]>> => {
  try {
    const data = await axiosInstance.get(
      ExerciseRequestFormEndpoints.GET_STATE_REGIONS,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const getAllFrequencies = async (
  token: string,
  id: number,
): Promise<IResponse<IExerciseRequestFrequency[]>> => {
  try {
    const data = await axiosInstance.get(
      `${ExerciseRequestFormEndpoints.EXERCISE_REQUEST_FREQUENCIES}/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const createExerciseFrequency = async (
  token: string,
  id: number,
): Promise<IResponse<IExerciseRequestFrequency>> => {
  try {
    const data = await axiosInstance.post(
      ExerciseRequestFormEndpoints.EXERCISE_REQUEST_FREQUENCIES,
      {
        exerciseRequestId: id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const updateFrequencyById = async (
  token: string,
  id: number,
  params: {
    hopset: string
    saturn: boolean
    platform: string
    locationId: number | null
    usageTimeId: number | null
    percentTime: number
    majorFunctionId: number | null
    receiveLocationId: number | null
    usageTimeServiceId: number | null
    detailedFunctionId: number | null
    numberOfFrequencies: number
    supplementaryDetails: string
    intermediateFunctionId: number | null
    isDifferentReceiveLocation: boolean
  },
): Promise<IResponse<IExerciseRequestFrequency>> => {
  try {
    const data = await axiosInstance.put(
      `${ExerciseRequestFormEndpoints.EXERCISE_REQUEST_FREQUENCIES}/${id}`,
      params,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const deleteFrequencyById = async (
  token: string,
  id: number,
): Promise<IResponse<null>> => {
  try {
    const data = await axiosInstance.delete(
      `${ExerciseRequestFormEndpoints.EXERCISE_REQUEST_FREQUENCIES}/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const getAllMajorFunctions = async (
  token: string,
): Promise<IResponse<IMajorFunction[]>> => {
  try {
    const data = await axiosInstance.get(
      ExerciseRequestFormEndpoints.GET_MAJOR_FUNCTIONS,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const getIntermediateFunctionsByMajorFunctionId = async (
  token: string,
  id: number,
): Promise<IResponse<IIntermediateFunction[]>> => {
  try {
    const data = await axiosInstance.get(
      `${ExerciseRequestFormEndpoints.GET_INTERMEDIATE_FUNCTIONS}/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const getDetailedFunctionsByIntermediateFunctionId = async (
  token: string,
  id: number,
): Promise<IResponse<IDetailedFunction[]>> => {
  try {
    const data = await axiosInstance.get(
      `${ExerciseRequestFormEndpoints.GET_DETAILED_FUNCTIONS}/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const getAllSingleFrequencies = async (
  token: string,
  id: number,
): Promise<IResponse<IExerciseRequestFrequencySingle[]>> => {
  try {
    const data = await axiosInstance.get(
      `${ExerciseRequestFormEndpoints.EXERCISE_REQUEST_FREQUENCIES_SINGLE}/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const createSingleFrequency = async (
  token: string,
  id: number,
  isExcluded?: boolean,
): Promise<IResponse<IExerciseRequestFrequencySingle>> => {
  try {
    const data = await axiosInstance.post(
      `${ExerciseRequestFormEndpoints.EXERCISE_REQUEST_FREQUENCIES_SINGLE}/${id}`,
      {
        isExcluded,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const updateSingleFrequency = async (
  token: string,
  id: number,
  params: {
    start_freq: number
    start_indicator: string
    frequency_template_id: number
    end_freq: number
    end_indicator: string
    is_band: boolean
  },
): Promise<IResponse<IExerciseRequestFrequencySingle>> => {
  try {
    const data = await axiosInstance.put(
      `${ExerciseRequestFormEndpoints.EXERCISE_REQUEST_FREQUENCIES_SINGLE}/${id}`,
      params,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const deleteSingleFrequency = async (
  token: string,
  id: number,
): Promise<IResponse<null>> => {
  try {
    const data = await axiosInstance.delete(
      `${ExerciseRequestFormEndpoints.EXERCISE_REQUEST_FREQUENCIES_SINGLE}/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const getAllFrequencyTemplates = async (
  token: string,
): Promise<IResponse<IFrequencyTemplate[]>> => {
  try {
    const data = await axiosInstance.get(
      ExerciseRequestFormEndpoints.GET_FREQUENCY_TEMPLATES,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const getAllUsageTimes = async (
  token: string,
): Promise<IResponse<IUsageTime[]>> => {
  try {
    const data = await axiosInstance.get(
      ExerciseRequestFormEndpoints.GET_USAGE_TIMES,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}
export const getAllUsageTimeServices = async (
  token: string,
): Promise<IResponse<IUsageTimeService[]>> => {
  try {
    const data = await axiosInstance.get(
      ExerciseRequestFormEndpoints.GET_USAGE_TIME_SERVICES,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}
