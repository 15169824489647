import { useContext, useEffect } from 'react'
import { Box } from '@mui/material'
import { useAuth0 } from '@auth0/auth0-react'
import Loader from './components/shared/Loader'
import ErrorPage from './components/shared/ErrorPage'
import RoutesComponent from './routers'
import { getUserByEmail } from './services/auth.service'
import { AuthContext } from './contexts/auth.context'

const App = () => {
  const { isLoading, isAuthenticated, error, user, getAccessTokenSilently } =
    useAuth0()

  const { user: AuthUser, setUser } = useContext(AuthContext)

  const getUser = async () => {
    if (user?.email) {
      const data = await getUserByEmail(
        await getAccessTokenSilently(),
        user.email,
        user.nickname || '',
      )

      if (data.data) {
        setUser(data.data)
      }
    }
  }

  useEffect(() => {
    if (user) {
      getUser()
    }
  }, [user])

  if (error) {
    return <ErrorPage code={400} message={error.message} />
  }

  if (isLoading || (isAuthenticated && !AuthUser)) {
    return <Loader />
  }

  return (
    <Box className="App">
      <RoutesComponent />
    </Box>
  )
}

export default App
