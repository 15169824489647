import { useContext } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Box, Button, Typography } from '@mui/material'
import SPEX_logo from '../../assets/spex_logo.png'
import OktaBlack from '../../assets/okta-black.png'
import OktaWhite from '../../assets/okta-white.png'
import { PlaybookContext } from '../../contexts/playbooks.context'

import styles from './styles.module.scss'

const Login = () => {
  const { isLightMode } = useContext(PlaybookContext)
  const { loginWithRedirect } = useAuth0()

  return (
    <Box className={styles.login}>
      <img src={SPEX_logo} className={styles.spexLogo} />
      <Box className={styles.btnContainer}>
        <Button
          className={styles.loginBtn}
          onClick={() => loginWithRedirect({})}
        >
          <Typography>Connect</Typography>
          <img
            className={styles.auth0Logo}
            src={isLightMode ? OktaBlack : OktaWhite}
          />
        </Button>
      </Box>
    </Box>
  )
}

export default Login
