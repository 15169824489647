import { createContext, ReactNode, useState } from 'react'
import { IAuthContext, IUser } from '../types/auth.type'

export const AuthContext = createContext<IAuthContext>({
  user: null,
  setUser: () => {},
})

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<IUser | null>(null)

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {children}
    </AuthContext.Provider>
  )
}
